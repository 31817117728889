.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: $white;
  opacity: 0.7;
  z-index: 999;
  .status {
    position: "absolute";
    left: "50%";
    top: "50%";
    transform: "translate(-50%; -50%)";
    .sr-only {
      display: none !important;
    }
  }
}

body {
  display: block !important;
}

.ctext-content {
  white-space: pre-wrap;
  max-width: 100%;
}
.ctext-content * {
  white-space: pre-wrap;
  max-width: 100%;
  margin: 0;
}

.ctext-content_markdown p {
  white-space: pre-wrap;
}

.user-chat-topbar {
  padding-top: 2.5rem !important;
}

.menu-bottom {
  padding-bottom: 4.5rem;
}

.modal-title-custom {
  .modal-title {
    font-size: 16px !important;
  }
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
  }
}

.alert-dismiss-custom {
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.user-chat-topbar .topbar-bookmark .close {
  padding: 12px 20px;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 490px) {
  .highlighter {
    max-width: 260px;
  }
}

@media screen and (max-width: 400px) {
  .highlighter {
    max-width: 210px;
  }
}

.selectable {

  -webkit-user-select: text;
  
  -moz-user-select: text;
  
  -ms-user-select: text;
  
  user-select: text;
  
  }
